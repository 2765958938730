import { useEffect, useState } from 'react'

import IconCheckboxChecked from '../Icon/IconCheckboxChecked'
import IconCheckboxIndeterminate from '../Icon/IconCheckboxIndeterminate'

import styles from './index.module.css'

interface Props {
  checked?: boolean
  indeterminate?: boolean
  onChange?: (value: boolean) => void
}

export default function Checkbox({
  checked = false,
  indeterminate = false,
  onChange,
}: Props): JSX.Element {
  const [selected, setSelected] = useState<boolean>(checked)

  const handleChange = (): void => {
    setSelected(!selected)

    onChange && onChange(!selected)
  }

  useEffect(() => {
    setSelected(checked)
  }, [checked])

  return (
    <div
      className={`${styles.checkbox} ${selected ? styles.active : ''} ${
        indeterminate ? styles.indeterminate : ''
      }`}
      onClick={handleChange}
    >
      {selected ? (
        <IconCheckboxChecked />
      ) : indeterminate ? (
        <IconCheckboxIndeterminate />
      ) : null}
    </div>
  )
}
