export const routes = {
  home: '/',
  login: '/auth/login',
  dashboards: '/dashboards',
  executiveSummary: '/executive-summary',
  lendingDecision: '/lending-decision',
  lendingDecisionDetails: '/lending-decision/details',
  decisionsMade: '/decisions-made',
  configuration: '/configuration',
  resources: '/resources'
}
