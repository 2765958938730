import Tab from './Tab'
import TabPane from './TabPane'

type TabComponent = typeof Tab

interface TabsComponent extends TabComponent {
  TabPane: typeof TabPane
}

const Tabs: TabsComponent = Tab as TabsComponent

Tabs.TabPane = TabPane

export default Tabs
