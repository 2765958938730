import { useState, useEffect } from 'react';
import Spin from '../../components/Spin';
import generateToken from './jwt';

declare global {
  interface Window {
    tableau: any;
  }
}

export default function Dashboards(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://prod-uk-a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js';
    script.type = 'module';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await generateToken(
          '787c3e30-57ba-44e2-89c4-6678ea538e59',
          'mD98YV4k92ZFBVaR570JxLqwao0dHDSaIh+p3JH09zA=',
          '9e9492d5-7a32-4791-90bd-e0d212d6c455',
          'rishavgarg@finexos.io'
        );
        setToken(token);
        setLoading(false);
      } catch (error) {
        console.log('Token could not be generated', error);
      }
    };

    setLoading(true);
    fetchData();
  }, []);

  return (
    <Spin spinning={loading}>
      <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
        { !loading && token !== '' &&
            <tableau-viz
              id="tableau-viz"
              hideTabs={true}
              src="https://prod-uk-a.online.tableau.com/t/finexos/views/MergedDatasetNew/LoanBook"
              toolbar="bottom"
              token={token}
              >
            </tableau-viz>}
      </div>
    </Spin>
  );
}