import {SignJWT} from 'jose';
import { v4 as uuidv4 } from 'uuid';



const generateToken = async (clientId: string, secretValue: string, secretId: string, username: string) => {
  try {
    const scopes = ['tableau:views:embed', 'tableau:metrics:embed'];

    const header = {
      kid: secretId,
      iss: clientId,
      alg: 'HS256',
    };
    const secret = new TextEncoder().encode(secretValue);

    const jtis = uuidv4();
    const payload = 
      {
        iss: clientId,
        aud: 'tableau',
        sub: username,
        exp: Math.floor(Date.now() / 1000) + 60 * 10, // Expires in 10 minutes
        jti: jtis,
        scp: scopes,
      };
      const token = new SignJWT(payload)
      .setProtectedHeader(header)
      // .setSubject(username)
      // .setAudience('tableau')
      // .setIssuedAt(now)
      // .setExpirationTime(now + 600)
      // .setJti(jtis)
      .sign(secret);

    return token;
  } catch (error) {
    console.error('Error generating token:', error);
    throw error;
  }
};

export default generateToken;

