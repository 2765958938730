import { CSSProperties, ReactNode, SyntheticEvent } from 'react'
import classnames from 'classnames'

import Spin from '../Spin'

import styles from './index.module.css'

interface Props {
  children: ReactNode
  htmlType?: 'submit' | 'reset' | 'button'
  type?: 'primary' | 'secondary' | 'danger' | 'link' | 'default' | 'ghost'
  size?: 'sm' | 'md' | 'lg'
  link?: boolean
  ghost?: boolean
  disabled?: boolean
  block?: boolean
  loading?: boolean
  className?: string
  style?: CSSProperties
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void
}

export default function Button({
  children,
  htmlType = 'button',
  type = 'primary',
  size = 'md',
  link,
  ghost,
  disabled,
  block,
  loading,
  className,
  style,
  onClick,
}: Props) {
  const handleClick = (e: SyntheticEvent<HTMLButtonElement>): void => {
    if (loading) {
      return
    }

    if (disabled) {
      return
    }

    if (onClick) {
      onClick(e)
    }
  }

  const btnClass = classnames(
    styles.btn,
    styles[`btn-${type}`],
    styles[`btn-${size}`],
    className,
    {
      [styles['btn-link']]: link,
      [styles['btn-ghost']]: ghost,
      [styles['btn-block']]: block,
      [styles['btn-disabled']]: disabled,
    }
  )

  return (
    <button
      className={btnClass}
      style={style}
      type={htmlType}
      onClick={handleClick}
    >
      {children}
      {loading ? <Spin /> : null}
    </button>
  )
}
