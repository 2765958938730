import React from 'react'

export interface UserInfo {
  username: string
  email: string
  phone: string
}

interface UserInfoContextProps {
  userInfo: UserInfo | null
  setUserInfo: (data: UserInfo | null) => void
}

export const UserInfoContext = React.createContext<UserInfoContextProps>({
  userInfo: null,
  setUserInfo: () => {},
})
