import { useState, useEffect, useRef, useMemo } from 'react'
import * as echarts from 'echarts'
import {
  map,
  flatMap,
  concat,
  without,
  find,
  uniq,
  zipObject,
  get,
  sortBy,
} from 'lodash'

import IconChecked from '../../../components/Icon/IconChecked'
import {
  CreditScoreType,
  getPendingApplicants,
  GetPendingDecisionRequest,
  GetPendingDecisionResponseItem,
} from '../../../api/lending'
import {
  getSettingGroups,
  GroupItem,
  SettingGroupsResponse,
} from '../../../api/configuration'
import { bandColors } from '../../../constants/colors'

import styles from './PendingApplicant.module.css'

interface CreditScoreItem {
  name: string
  value: CreditScoreType
  groupKey: 'experianGroups' | 'tuGroups' | 'equifaxGroups'
}

const creditScoreList: CreditScoreItem[] = [
  {
    name: 'Experian Score',
    value: 'EXPERIAN',
    groupKey: 'experianGroups',
  },
  {
    name: 'TU Score',
    value: 'TU',
    groupKey: 'tuGroups',
  },
  {
    name: 'Equifax',
    value: 'EQUIFAX',
    groupKey: 'equifaxGroups',
  },
]

export const levelColors: Record<string, string> = {
  'very poor': '#000000',
  poor: '#400034',
  fair: '#800068',
  good: '#bf009c',
  excellent: '#ff00d0',
}

export default function PendingApplicant(): JSX.Element {
  const [settingGroups, setSettingGroups] = useState<
    SettingGroupsResponse | undefined
  >()
  const fcsGroups = useMemo(() => {
    return settingGroups?.fcsGroups || []
  }, [settingGroups])
  const [activeFcsGroups, setActiveFcsGroups] = useState<string[]>([])
  const fcsGroupColors = useMemo<Record<string, string>>(() => {
    const grades = map(fcsGroups, 'grade')
    const groups = uniq(map(grades, (item) => item[0])).sort()
    const colors = zipObject(
      groups,
      map(groups, (_item, index) => bandColors[index % bandColors.length])
    )

    const results = zipObject(
      grades,
      map(fcsGroups, (item) => {
        const initial = item.grade[0]

        return colors[initial]
      })
    )

    return results
  }, [fcsGroups])
  const fcsGroupCircles = useMemo(() => {
    const circles = map(fcsGroups, (item) => {
      const maxSize = 900
      const max = Math.floor((item.max * maxSize) / 50)
      const min = Math.floor((item.min * maxSize) / 50)
      const name = item.grade[0]
      const color = fcsGroupColors[item.grade]

      return {
        grade: item.grade,
        name,
        max,
        min,
        color,
      }
    })

    return circles.sort((prev, next) => next.max - prev.max)
  }, [fcsGroups, fcsGroupColors])

  const toggleFcsGroup = (value: string): void => {
    let groups = activeFcsGroups

    if (activeFcsGroups.includes(value)) {
      groups = without(activeFcsGroups, value)
    } else {
      groups = concat(activeFcsGroups, [value])
    }

    setActiveFcsGroups(groups)
  }

  const [scoreType, setScoreType] = useState<CreditScoreType>('EXPERIAN')
  const scoreLevels = useMemo<GroupItem[]>(() => {
    if (!settingGroups) {
      return []
    }

    const creditScore = find(creditScoreList, {
      value: scoreType,
    })

    if (!creditScore) {
      return []
    }

    if (!settingGroups[creditScore.groupKey]) {
      return []
    }

    return sortBy(settingGroups[creditScore.groupKey], 'max')
  }, [settingGroups, scoreType])
  const [activeScoreLevels, setActiveScoreLevels] = useState<string[]>([])
  const activeScoreTypeName = get(
    find(creditScoreList, {
      value: scoreType,
    }),
    'name',
    ''
  )

  const toggleScoreType = (value: CreditScoreType): void => {
    setScoreType(value)

    const scoreItem = find(creditScoreList, {
      value,
    })

    if (scoreItem) {
      const levels = (settingGroups && settingGroups[scoreItem.groupKey]) || []
      const levelNames = map(levels, 'grade')

      setActiveScoreLevels(levelNames)
    } else {
      setActiveScoreLevels([])
    }
  }

  const toggleScoreLevel = (value: string): void => {
    let levels = activeScoreLevels

    if (activeScoreLevels.includes(value)) {
      levels = without(activeScoreLevels, value)
    } else {
      levels = concat(activeScoreLevels, [value])
    }

    setActiveScoreLevels(levels)
  }

  useEffect(() => {
    const fetchSettingGroups = async (): Promise<void> => {
      try {
        const response = await getSettingGroups()

        setSettingGroups(response)

        const fcsGroups = map(response.fcsGroups, 'grade')

        setActiveFcsGroups(fcsGroups)

        setActiveScoreLevels(map(response.experianGroups, 'grade'))
      } catch (err) {
        console.error(err)
      }
    }

    fetchSettingGroups()
  }, [])

  const [chart, setChart] = useState<echarts.ECharts | null>(null)
  const chartDom = useRef<HTMLDivElement>(null)
  const [data, setData] = useState<GetPendingDecisionResponseItem[]>([])

  useEffect(() => {
    if (!chartDom.current) {
      return
    }

    const c = echarts.init(chartDom.current)

    c.setOption({
      tooltip: {
        show: true,
        formatter(params: any) {
          const { data } = params

          return `Score: ${data[2]}`
        },
      },
      grid: {
        bottom: '10%',
      },
      xAxis: {
        name: 'Debt/Income Ratio',
        nameLocation: 'center',
        nameGap: 30,
        type: 'value',
        min: 0,
        max: 1,
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        name: 'FCS',
        type: 'value',
        min: 0,
        max: 50,
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval:10
        },
        splitLine: {
          show: false,
        },
        
      },
      series: [],
      animation: false,
    })

    setChart(c)

    return () => {
      c.dispose()
    }
  }, [])

  useEffect(() => {
    if (!chart) {
      return
    }

    if (!data) {
      chart.showLoading()
      return
    }

    chart.hideLoading()

    const chartData = map(data, (item) => {
      let extra = 0

      switch (scoreType) {
        case 'EQUIFAX':
          extra = item.equifaxScore
          break
        case 'EXPERIAN':
          extra = item.experianScore
          break
        case 'TU':
          extra = item.tuScore
          break
        default:
          extra = 0
      }

      return [item.dti, item.fcs, extra]
    })

    chart.setOption({
      yAxis: {
        name: 'FCS',
        type: 'value',
        min: 0,
        max: 50,
        axisTick: {
          show: false,
        },
        axisLabel: {
          interval: 0,
          formatter(value: number) {
            // const group = find(fcsGroups, (item) => {
            //   console.log(item);
            //   return value >= Math.floor(item.min) && value <= Math.ceil(item.max);
            // });
            // console.log(group?.grade);
      
            // if (group) {
            //   return group.grade[0]
            // }
      
            return "";
          },
        },
        splitLine: {
          show: false,
        },
      },
      
      
      
      series: [
        {
          symbolSize: 5,
          type: 'scatter',
          data: chartData,
          itemStyle: {
            color(params: any) {
              const value = params.data[2]
              const level = find(
                scoreLevels,
                (item) => value >= item.min && value <= item.max
              )

              if (level) {
                return levelColors[level.grade.toLowerCase()]
              }

              return params.color
            },
          },
        },
        {
          

        },
      ],
    })
  }, [chart, data, fcsGroups, scoreType, scoreLevels])

  useEffect(() => {
    if (!chart || !fcsGroups.length) {
      return
    }

    const fetchData = async () => {
      chart.showLoading()

      try {
        const payload: GetPendingDecisionRequest = {
          scoreType,
          grades: flatMap(activeFcsGroups, (item) => {
            return item.includes('-') ? item.split('-') : item
          }).join(','),
          scores: activeScoreLevels.join(','),
        }

        const response = await getPendingApplicants(payload)

        setData(response)
      } catch (err) {
        console.error(err)
      } finally {
        chart.hideLoading()
      }
    }

    fetchData()
  }, [fcsGroups, activeFcsGroups, scoreType, activeScoreLevels, chart])

  const handleReset = (): void => {
    if (!settingGroups) {
      return
    }

    const fcsGroups = map(settingGroups.fcsGroups, 'grade')

    setActiveFcsGroups(fcsGroups)

    setScoreType('EXPERIAN')
    setActiveScoreLevels(map(settingGroups.experianGroups, 'grade'))
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Lending Decision Assistant</div>
      <div className={styles.box}>
        <div className={styles.header}>
          <span>Filters</span>
          <strong onClick={handleReset}>Clear All Filters</strong>
        </div>
        <div className={styles.content}>
          <div className={styles['grade-box']}>
            <h4 className={styles['sub-title']}>Filter By Finexos Grade</h4>
            <ul className={styles['grade-list']}>
              {fcsGroups.map((item) => (
                <li
                  className={`${styles['grade-item']} ${
                    activeFcsGroups.includes(item.grade)
                      ? styles['grade-item-selected']
                      : ''
                  }`}
                  key={item.grade}
                  onClick={() => toggleFcsGroup(item.grade)}
                >
                  <i
                    style={{ backgroundColor: fcsGroupColors[item.grade] }}
                  ></i>
                  <span className={styles['grade-text']}>{item.grade}</span>
                  {activeFcsGroups.includes(item.grade) && (
                    <span className={styles['grade-checked-icon']}>
                      <IconChecked />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className={styles['score-box']}>
            <h4 className={styles['sub-title']}>Filter By Credit Score</h4>
            <ul className={styles['score-filter-list']}>
              {creditScoreList.map((item) => (
                <li
                  key={item.name}
                  className={styles['score-filter-item']}
                  onClick={() => toggleScoreType(item.value)}
                >
                  <span
                    className={`${styles['score-filter-radio']} ${
                      scoreType === item.value
                        ? styles['score-filter-radio-selected']
                        : ''
                    }`}
                  ></span>
                  <span className={styles['score-filter-text']}>
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
            <div className={styles['score-bar']}>
              <div className={styles['score-bar-title']}>
                {activeScoreTypeName}
              </div>
              <ul className={styles['score-bar-list']}>
                {scoreLevels.map((item) => (
                  <li
                    className={styles['score-bar-item']}
                    key={item.grade}
                    style={{
                      backgroundColor: levelColors[item.grade.toLowerCase()],
                    }}
                    onClick={() => toggleScoreLevel(item.grade)}
                  >
                    <i>
                      {activeScoreLevels.includes(item.grade) && (
                        <IconChecked />
                      )}
                    </i>
                    <span>{item.grade}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={styles['chart-box']}>
            <div className={styles['chart-bg-box']}>
              {fcsGroupCircles.map((item) => (
                <div key={item.grade}>
                  <div
                    className={styles['chart-bg']}
                    style={{
                      width: `1020px`,
                      //${item.max}
                      height: `${item.max + 70}px`,
                      backgroundColor: `${item.color}`,
                    }}
                  ></div>
                  <div
                    className={styles['chart-bg']}
                    key={item.grade}
                    style={{
                      width: `${item.min}px`,
                      height: `${item.min}px`,
                      backgroundColor: '#f2655s',
                    }}
                  ></div>
                </div>
              ))}
            </div>
            <div className={styles.chart} ref={chartDom} ></div>
          </div>
        </div>
      </div>
    </div>
  )
}
