import { useState, useEffect, useRef } from 'react'
import { groupBy, uniq, mapValues, map, find } from 'lodash-es'
import * as echarts from 'echarts'
import { AnnualExpensesItem } from '../../../api/lending'

import styles from './Chart.module.css'

interface Props {
  data?: AnnualExpensesItem[]
}

const barItemColors = [
  'rgba(130, 236, 153, 0.8)',
  'rgba(57, 163, 166, 0.8)',
  'rgba(34, 87, 123, 0.8)',
]

export default function AnnualExpenses({ data }: Props): JSX.Element {
  const [chart, setChart] = useState<echarts.ECharts | null>(null)
  const chartDom = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!chartDom.current) {
      return
    }

    const c = echarts.init(chartDom.current)

    c.setOption({
      legend: {
        right: 100,
      },
      tooltip: {},
      xAxis: {
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
      },
      yAxis: {
        name: 'Average Mean £',
        nameLocation: 'middle',
        nameGap: 50,
      },
      grid: {
        bottom: 80,
      },
      series: [],
    })

    setChart(c)

    return () => {
      c.dispose()
    }
  }, [])

  useEffect(() => {
    if (!chart) {
      return
    }

    if (!data) {
      chart.showLoading()
      return
    }

    chart.hideLoading()

    const categories = uniq(data.map((item) => item.category))
    const values = mapValues(groupBy(data, 'year'), (value) => {
      return map(categories, (item) => {
        const v = find(value, {
          category: item,
        })

        return v ? v.value : 0
      })
    })
    const series = map(
      map(values, (value, key) => ({
        name: key,
        data: value,
        type: 'bar',
      })),
      (item, index) => ({
        ...item,
        itemStyle: {
          color: barItemColors[index % barItemColors.length],
        },
      })
    )

    chart.setOption({
      xAxis: {
        data: categories,
      },
      series,
    })
  }, [chart, data])

  return (
    <div className={styles.container}>
      <div className={styles.title}>Annual Expenses Analysis</div>
      <div className={styles.chart} ref={chartDom}></div>
    </div>
  )
}
