import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../components/Button'
import IconPasswordEye from '../../components/Icon/IconPasswordEye'
import IconPasswordEyeClose from '../../components/Icon/IconPasswordEyeClose'
import styles from './Login.module.css'
import { login } from '../../api/auth'
import { isValidEmail, isValidPassword } from '../../utilities/validate'
import { ResponseError } from '../../api/errors'
import { routes } from '../../constants/routes'
import { setToken } from '../../utilities/storage'
import { UserInfoContext } from '../../context/user-info'

type EmailValidationError = 'empty' | 'invalid' | ''
type PasswordValidationError = 'empty' | 'invalid' | 'mismatch' | ''

const getEmailValidationResult = (value: string): EmailValidationError => {
  if (!value) {
    return 'empty'
  }

  const valid = isValidEmail(value)

  if (!valid) {
    return 'invalid'
  }

  return ''
}

const getPasswordValidationResult = (
  value: string
): PasswordValidationError => {
  if (!value) {
    return 'empty'
  }

  const valid = isValidPassword(value)

  if (!valid) {
    return 'invalid'
  }

  return ''
}

export default function Login(): JSX.Element {
  const navigate = useNavigate()
  const { setUserInfo } = useContext(UserInfoContext)
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<EmailValidationError>('')

  const validateEmail = (): EmailValidationError => {
    const error = getEmailValidationResult(email)

    setEmailError(error)

    return error
  }

  const handleInputEmail = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target

    setEmail(value)
  }

  const handleFocusEmail = (): void => {
    setEmailError('')
  }

  const handleBlurEmail = (): void => {
    validateEmail()
  }

  const [password, setPassword] = useState<string>('')
  const [passwordError, setPasswordError] =
    useState<PasswordValidationError>('')

  const validatePassword = (): PasswordValidationError => {
    const error = getPasswordValidationResult(password)

    setPasswordError(error)

    return error
  }

  const handleInputPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target

    setPassword(value)
  }

  const handleFocusPassword = (): void => {
    setPasswordError('')
  }

  const handleBlurPassword = (): void => {
    validatePassword()
  }

  const [passwordType, setPasswordType] = useState<'password' | 'text'>(
    'password'
  )

  const handleTogglePasswordType = (): void => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password')
  }

  const [submitting, setSubmitting] = useState<boolean>(false)

  const handleSubmit = async (
    e: React.ChangeEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault()

    const emailError = validateEmail()
    const passwordError = validatePassword()

    if (emailError || passwordError) {
      return
    }

    setSubmitting(true)

    try {
      const response = await login({
        email,
        password,
      })

      setToken(response.token)
      setUserInfo({
        username: response.username,
        email: response.email,
        phone: response.phone,
      })
      navigate(routes.home)
    } catch (err) {
      const { name, message } = err as ResponseError

      if (name === 'error.email-or-password-invalid') {
        setPasswordError('mismatch')
        return
      }

      console.error(message)
      return
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className={styles.bkg}>
      <div className={styles.title}>Log In</div>
      <div className={styles.form}>
        <form onSubmit={handleSubmit}>
          <div
            className={`${styles['form-item']} ${
              emailError ? styles['form-error'] : ''
            }`}
          >
            <label className={styles.label} htmlFor="email">
              Email
            </label>
            <div className={styles['form-content']}>
              <input
                id="email"
                className={styles.input}
                type="text"
                onInput={handleInputEmail}
                onFocus={handleFocusEmail}
                onBlur={handleBlurEmail}
              />
              {emailError === 'empty' && (
                <p className={styles['form-error-tips']}>
                  Please Input a Email Address.
                </p>
              )}
              {emailError === 'invalid' && (
                <p className={styles['form-error-tips']}>
                  Please Input a Valid Email Address.
                </p>
              )}
            </div>
          </div>
          <div
            className={`${styles['form-item']} ${
              passwordError ? styles['form-error'] : ''
            }`}
          >
            <label className={styles.label} htmlFor="password">
              Password
            </label>
            <div className={styles['form-content']}>
              <input
                id="password"
                className={styles.input}
                type={passwordType}
                onInput={handleInputPassword}
                onFocus={handleFocusPassword}
                onBlur={handleBlurPassword}
              />
              <div
                className={styles['form-input-eye-icon']}
                onClick={handleTogglePasswordType}
              >
                {passwordType === 'password' ? (
                  <IconPasswordEye />
                ) : (
                  <IconPasswordEyeClose />
                )}
              </div>
              {passwordError === 'empty' && (
                <p className={styles['form-error-tips']}>
                  Please Input a Password.
                </p>
              )}
              {passwordError === 'invalid' && (
                <p className={styles['form-error-tips']}>
                  Sorry Your password must 8 or more characters with a mix of
                  letters, numbers.
                </p>
              )}
              {passwordError === 'mismatch' && (
                <p className={styles['form-error-tips']}>
                  This email address and password do not match up, Please try
                  again.
                </p>
              )}
            </div>
          </div>
          <div className={styles['form-footer']}>
            <Button htmlType="submit" loading={submitting} block>
              Log In
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
