import { PropsWithChildren } from 'react'
import styles from './index.module.css'

interface Props extends PropsWithChildren {
  visible?: boolean
  onClose?: () => void
}

export default function Modal({
  visible = false,
  onClose,
  children,
}: Props): JSX.Element | null {
  if (!visible) {
    return null
  }

  const handleMaskClick = (): void => {
    onClose && onClose()
  }

  return (
    <div className={styles.container}>
      <div className={styles.mask} onClick={handleMaskClick}></div>
      <div className={styles.box}>{children}</div>
    </div>
  )
}
