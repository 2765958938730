import axios, { AxiosRequestConfig } from 'axios'
import { allErrors, errorMessages, ResponseErrorCode } from './errors'
import { getToken } from '../utilities/storage'
import { routes } from '../constants/routes'

const env = window.__RUNTIME_CONFIG__ || process.env

const apiHost = env.REACT_APP_API_HOST || ''

const request = axios.create({
  baseURL: `${apiHost}/api/v1`,
  timeout: 20000,
})

request.interceptors.request.use((config): AxiosRequestConfig => {
  const token = getToken()

  if (token) {
    if (!config.headers) {
      config.headers = {}
    }

    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

request.interceptors.response.use(
  (response) => {
    return response.data
  },
  (err) => {
    if (err.response) {
      const { status, statusText, data } = err.response

      if (status === 401) {
        window.location.href = routes.login

        return Promise.reject(new Error(statusText))
      }

      const message = data?.message || statusText
      const unknown = allErrors.indexOf(message) === -1

      if (unknown) {
        alert('Unexpected error. Please try again later')
      }

      const definedErrorMessage = errorMessages[message as ResponseErrorCode]

      if (definedErrorMessage) {
        alert(definedErrorMessage)
      }

      const error = new Error(message)

      error.name = message

      return Promise.reject(error)
    }

    return Promise.reject(err)
  }
)

export default request
