import React from 'react'
import { Icon, IconPropsWithoutChildren } from './Icon'
import { ReactComponent as IconSvg } from './icon-image/password-eye-close.svg'

export default function IconPasswordEyeClose(
  props: IconPropsWithoutChildren
): JSX.Element {
  return (
    <Icon {...props}>
      <IconSvg />
    </Icon>
  )
}
