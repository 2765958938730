import { useState } from 'react';
import styles from './index.module.css';

const Configuration = (): JSX.Element => {
  const [experian, setExperian] = useState<string>('');
  const [tu, setTU] = useState<string>('');
  const [equifax, setEquifax] = useState<string>('');
  const [fico, setFico] = useState<string>('');
  const [fcsScore, setFcsScore] = useState<string>('');
  const [apr, setApr] = useState<string>('');
  const [pd, setPd] = useState<string>('');

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <label htmlFor="experian">Experian:</label>
        <select id="experian" value={experian} onChange={(e) => setExperian(e.target.value)}>
          <option value="">Select an option</option>
          <option value="excellent">Excellent</option>
          <option value="good">Good</option>
          <option value="fair">Fair</option>
          <option value="poor">Poor</option>
          <option value="very_poor">Very Poor</option>
        </select>

        <label htmlFor="tu">TU:</label>
        <select id="tu" value={tu} onChange={(e) => setTU(e.target.value)}>
          <option value="">Select an option</option>
          <option value="excellent">Excellent</option>
          <option value="good">Good</option>
          <option value="fair">Fair</option>
          <option value="poor">Poor</option>
          <option value="very_poor">Very Poor</option>
        </select>

        <label htmlFor="equifax">Equifax:</label>
        <select id="equifax" value={equifax} onChange={(e) => setEquifax(e.target.value)}>
          <option value="">Select an option</option>
          <option value="excellent">Excellent</option>
          <option value="good">Good</option>
          <option value="fair">Fair</option>
          <option value="poor">Poor</option>
          <option value="very_poor">Very Poor</option>
        </select>

        <label htmlFor="fico">Fico:</label>
        <select id="fico" value={fico} onChange={(e) => setFico(e.target.value)}>
          <option value="">Select an option</option>
          <option value="excellent">Excellent</option>
          <option value="good">Good</option>
          <option value="fair">Fair</option>
          <option value="poor">Poor</option>
          <option value="very_poor">Very Poor</option>
        </select>
      </div>

      <div className={styles.box}>
        <label htmlFor="fcsScore">FCS Score:</label>
        <select id="fcsScore" value={fcsScore} onChange={(e) => setFcsScore(e.target.value)}>
          <option value="">Select an option</option>
          <option value="a">A1-A4</option>
          <option value="b">B1-B6</option>
          <option value="c">C1-C4</option>
          <option value="d">D1-D4</option>
          <option value="e">E1-E3</option>
        </select>

        <label htmlFor="apr">APR:</label>
        <select id="apr" value={apr} onChange={(e) => setApr(e.target.value)}>
          <option value="">Select an option</option>
          <option value="option1">17.4%</option>
          <option value="option2">19.6%</option>
          <option value="option3">21.4%</option>
          <option value="option1">23.5%</option>
          <option value="option2">25.2%</option>
          <option value="option3">27.3%</option>
          </select>

        <label htmlFor="pd">PD:</label>
        <select id="pd" value={pd} onChange={(e) => setPd(e.target.value)}>
          <option value="">Select an option</option>
          <option value="option1">0% - 20%</option>
          <option value="option2">21% - 40%</option>
          <option value="option3">41% - 60%</option>
          <option value="option3">61% - 80%</option>
          <option value="option3">81% - 100%</option>
        </select>
        </div>
        </div>
        );
        };

        export default Configuration;

