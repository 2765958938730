import { useState, useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { ExpenseComparisonItem } from '../../../api/lending';

import styles from './Chart.module.css';

interface Props {
  data?: ExpenseComparisonItem[];
}

export default function ExpenseComparison({ data }: Props): JSX.Element {
  const [chart, setChart] = useState<echarts.ECharts | null>(null);
  const chartDom = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!chartDom.current) {
      return;
    }

    const myChart = echarts.init(chartDom.current);

    const option = {
      tooltip: {},
      series: [
        {
          name: 'Expenses',
          type: 'pie',
          label: {
            width: 100,
            overflow: 'break',
          },
          radius: '50%',
          data: [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    }
    

    myChart.setOption(option);
    setChart(myChart);

    return () => {
      myChart.dispose();
    };
  }, []);

  useEffect(() => {
    if (!chart) {
      return;
    }

    if (!data) {
      chart.showLoading();
      return;
    }

    chart.hideLoading();

    chart.setOption({
      series: [
        {
          name: 'Expenses',
          type: 'pie',
          label: {
            width: 100,
            overflow: 'break',
          },
          radius: '50%',
          data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    });
  }, [chart, data]);

  return (
    <div className={styles['expense-container']}>
      <div className={styles.title}>Comparison of Essential and Non-essential Expenses</div>
      <div className={styles.pie} ref={chartDom} />
    </div>
  );
}
