export const allErrors = [
  // login
  'error.email-or-password-invalid',

  // band
  'error.setting-name-exists',
  'error.setting-band-overlap',
  'error.setting-blank-exists',
  'error.setting-min-larger-than-max',
] as const

export type ResponseErrorCode = typeof allErrors[number]

export interface ResponseError extends Error {
  name: ResponseErrorCode
}

export const errorMessages: Partial<Record<ResponseErrorCode, string>> = {
  'error.setting-band-overlap':
    'Intervals between all ranges are not allowed to overlap',
  'error.setting-blank-exists': 'Intervals between all ranges may have no gaps',
}
