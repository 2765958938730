import React from 'react'

import styles from './Icon.module.css'

interface IconProps {
  children: React.ReactNode
  onClick?: any
}

export type IconPropsWithoutChildren = Omit<IconProps, 'children'>

export function Icon({ children, onClick }: IconProps): JSX.Element {
  return (
    <span className={styles.icon} onClick={onClick}>
      {children}
    </span>
  )
}
