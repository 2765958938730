export function isValidEmail(email: string): boolean {
  // https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
  const emailReg = new RegExp(
    // eslint-disable-next-line
    '(?:[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])'
  )

  return emailReg.test(email)
}

export function isValidPassword(password: string): boolean {
  const wordsOnly = /^[a-zA-Z0-9]{8,}$/g.test(password)
  const atLeastOneNumber = /^(?=.*[0-9])/.test(password)
  const atLeastOneLetter = /^(?=.*[a-zA-Z])/.test(password)

  return wordsOnly && atLeastOneNumber && atLeastOneLetter
}

export function isValidBandName(value: string): boolean {
  return /^[a-zA-Z]{1}\d{0,2}$/.test(value)
}

export function isValidNumber(value: string): boolean {
  return !Number.isNaN(Number(value))
}

export function atMostDecimalPlaces(value: string, length = 3): boolean {
  const val = `${value}`.split('.')

  if (!val[1]) {
    return true
  }

  return val[1].length <= length
}
