import React from 'react'
import { Icon, IconPropsWithoutChildren } from './Icon'
import { ReactComponent as IconSvg } from './icon-image/left-black.svg'

export default function IconBack(
    props: IconPropsWithoutChildren
): JSX.Element {
  return (
      <Icon {...props}>
        <IconSvg />
      </Icon>
  )
}
