import request from './request'

export interface Band {
  id?: string
  rid?: string
  grade: string
  min: number
  max: number
}

export function getBands(): Promise<Band[]> {
  return request.get('/admin/settings/grades')
}

interface AddBandRequest {
  grade: string
  min: number
  max: number
}

export function addBand(data: AddBandRequest): Promise<void> {
  return request.post('/admin/settings/grade', data)
}

interface UpdateBandRequest {
  id: string
  grade: string
  min: number
  max: number
}

export function updateBand(data: UpdateBandRequest): Promise<void> {
  return request.put('/admin/settings/grade', data)
}

export function deleteBand(id: string): Promise<void> {
  return request.delete(`/admin/settings/grade/${id}`)
}

export interface GroupItem {
  grade: string
  min: number
  max: number
}

export interface SettingGroupsResponse {
  fcsGroups: GroupItem[]
  experianGroups: GroupItem[]
  tuGroups: GroupItem[]
  equifaxGroups: GroupItem[]
}

export function getSettingGroups(): Promise<SettingGroupsResponse> {
  return request.get('/admin/settings/groups')
}
