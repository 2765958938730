import { useState, useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { ExpenseAndIncomeItem } from '../../../api/lending'

import styles from './Chart.module.css'

interface Props {
  data?: ExpenseAndIncomeItem[]
}

export default function ExpensesIncome({ data }: Props): JSX.Element {
  const [chart, setChart] = useState<echarts.ECharts | null>(null)
  const chartDom = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!chartDom.current) {
      return
    }

    const c = echarts.init(chartDom.current)

    c.setOption({
      legend: {
        right: 100,
      },
      tooltip: {},
      xAxis: {
        data: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        axisLabel: {
          interval: 0,
        },
      },
      yAxis: {
        name: 'Average Expenses Monthly £',
        nameLocation: 'middle',
        nameGap: 50,
      },
      grid: {},
      series: [
        {
          name: 'Expenses',
          type: 'bar',
          itemStyle: {
            color: 'rgba(57, 163, 166, 0.8)',
          },
          data: [],
        },
        {
          name: 'Income',
          type: 'line',
          lineStyle: {
            color: '#22577b',
            width: 2,
            type: 'dashed',
          },
          itemStyle: {
            color: '#22577b',
          },
          data: [],
        },
      ],
    })

    setChart(c)

    return () => {
      c.dispose()
    }
  }, [])

  useEffect(() => {
    if (!chart) {
      return
    }

    if (!data) {
      chart.showLoading()
      return
    }

    chart.hideLoading()

    const months = data.map((item) => item.month)
    const expenses = data.map((item) => item.expense)
    const incomes = data.map((item) => item.income)

    chart.setOption({
      xAxis: {
        data: months,
      },
      series: [
        {
          name: 'Expenses',
          data: expenses,
        },
        {
          name: 'Income',
          data: incomes,
        },
      ],
    })
  }, [chart, data])

  return (
    <div className={styles.container}>
      <div className={styles.title}>Expenses VS Income per Month</div>
      <div className={styles.chart} ref={chartDom}></div>
    </div>
  )
}
