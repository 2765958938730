import request from './request'

interface LoginRequest {
  email: string
  password: string
}

interface LoginResponse {
  token: string
  username: string
  email: string
  phone: string
}

export function login(data: LoginRequest): Promise<LoginResponse> {
  return request.post('/admin/accounts/login', data)
}
