import { useState, useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { ExpenseDistributionItem } from '../../../api/lending'

import styles from './Chart.module.css'

interface Props {
  data?: ExpenseDistributionItem[]
}

export default function ExpenseDistribution({ data }: Props): JSX.Element {
  const [chart, setChart] = useState<echarts.ECharts | null>(null)
  const chartDom = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!chartDom.current) {
      return
    }

    const myChart = echarts.init(chartDom.current)

    const option = {
      tooltip: {},
      series: [
        {
          name: 'Expenses',
          type: 'pie',
          label: {
            width: 150,
            overflow: 'truncate',
            ellipsis: '...'
          },
          color: [
            '#5c60cc',
            '#4287f5',
            '#E690D1',
            '#387385',
            '#ba2f77',
            '#388573',
            '#f08437',
            '#63801c',
            '#de5479',
            '#f2c56b',
            '#f74825',
            '#73a373',
            '#E062AE',
            '#8567a1',
            '#683696',
            '#96BFFF',
            '#e69d87',
            '#cc545a',
            '#8dc1a9',
          ],
          radius: '50%',
          data: [],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            },
          }
        }
      ]
    }

    myChart.setOption(option)
    setChart(myChart)

    return () => {
      myChart.dispose()
    }

  }, [])

  useEffect(() => {
    if (!chart) {
      return
    }

    if (!data) {
      chart.showLoading()
      return
    }

    chart.hideLoading()

    chart.setOption({
      series: [
        {
          data
        }
      ],
    })
  }, [chart, data])

  return (
      <div className={styles['expense-container']}>
        <div className={styles.title}>Distribution of Expenses</div>
        <div className={styles.pie} ref={chartDom}/>
      </div>
  )
}
