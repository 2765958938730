import { PropsWithChildren } from 'react'

interface Props extends PropsWithChildren {
  key: string
  tab: string
}

export default function TabPane({ children }: Props): JSX.Element {
  return <div>{children}</div>
}
