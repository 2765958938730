import { useState, useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { DatePicker } from 'antd'
import { map } from 'lodash-es'

import Spin from '../../components/Spin'
import {
  ExecutiveSummaryResponse,
  getExecutiveSummary,
} from '../../api/lending'
import { percentage, separateThousands } from '../../utilities/num'

import styles from './index.module.css'
import { bandColors } from '../../constants/colors'

export default function ExecutiveSummary(): JSX.Element {
  const chartDom = useRef<HTMLDivElement>(null)
  const [chart, setChart] = useState<echarts.ECharts | null>(null)
  const [from, setFrom] = useState<string | undefined>()
  const [to, setTo] = useState<string | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<ExecutiveSummaryResponse>({
    approved: 0,
    expired: 0,
    pending: 0,
    total: 0,
    distribution: [],
  })
  const groups = map(data.distribution, 'group')
  const nums = map(data.distribution, 'num')
  const rates = map(data.distribution, 'rate')

  const handleFromChange = (value: any): void => {
    const val = value ? value.format('YYYY-MM-DD') : undefined

    setFrom(val)
  }
  const handleToChange = (value: any): void => {
    const val = value ? value.format('YYYY-MM-DD') : undefined

    setTo(val)
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      try {
        const response = await getExecutiveSummary({
          from,
          to,
        })

        setData(response)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [from, to])

  useEffect(() => {
    if (!chartDom.current) {
      return
    }

    const c = echarts.init(chartDom.current)

    c.setOption({
      color: bandColors,
      tooltip: {
        trigger: 'item',
        formatter(params: any) {
          const { name, value, rate } = params.data

          return `<strong>${name}:</strong><br />${value}&nbsp;&nbsp;&nbsp;${percentage(
            rate
          )}`
        },
      },
      series: [
        {
          name: 'Finexos Grade',
          type: 'pie',
          radius: '50%',
          label: {
            show: false,
          },
          data: [],
        },
      ],
    })

    setChart(c)

    return () => {
      c.dispose()
    }
  }, [])

  useEffect(() => {
    if (!chart) {
      return
    }

    if (!data) {
      chart.showLoading()
      return
    }

    chart.hideLoading()

    const chartData = map(data.distribution, (item) => ({
      name: item.group,
      value: item.num,
      rate: item.rate,
    }))

    chart.setOption({
      series: [
        {
          name: 'Finexos Grade',
          type: 'pie',
          radius: '50%',
          label: {
            show: false,
            formatter: '{b}: {d}%',
          },
          data: chartData,
        },
      ],
    })
  }, [chart, data])

  return (
    <Spin spinning={loading}>
      <div className="content-container">
        <div className={styles.title}>Executive Summary</div>
        <div className={styles.box}>
          <div className={styles.filter}>
            <span className={styles['filter-text']}>Filters</span>
            <div>
              <DatePicker onChange={handleFromChange} />
              <span className={styles['filter-to']}>to</span>
              <DatePicker onChange={handleToChange} />
            </div>
          </div>
          <div className={styles['chart-container']}>
            <div>
              <h3 className={styles['chart-title']}>Finexos Grade</h3>
              <div className={styles['chart-bar-box']}>
                <div className={styles['chart-bar']} ref={chartDom}></div>
              </div>
            </div>
            <div className={styles['chart-table']}>
              <table className="border center">
                <thead>
                  <tr>
                    <th>All Applicants</th>
                    {groups.map((item, index) => (
                      <th key={item}>
                        <span
                          className={styles['chart-table-dot']}
                          style={{
                            backgroundColor:
                              bandColors[index % bandColors.length],
                          }}
                        ></span>
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.total}</td>
                    {nums.map((item, index) => (
                      <td key={index}>{item}</td>
                    ))}
                  </tr>
                  <tr>
                    <td>100%</td>
                    {rates.map((item, index) => (
                      <td key={index}>{percentage(item)}</td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles['footer-item']}>
              <div>
                <div className={styles['footer-value']}>
                  <span>{separateThousands(data.approved)}</span>
                  <strong>&nbsp;/&nbsp;{separateThousands(data.total)}</strong>
                </div>
                <p className={styles['footer-text']}>
                  {percentage(data.approved / data.total)} Selected
                </p>
              </div>
            </div>
            <div className={styles['footer-item']}>
              <div>
                <div className={styles['footer-value']}>
                  <span>{separateThousands(data.pending)}</span>
                  <strong>&nbsp;/&nbsp;{separateThousands(data.total)}</strong>
                </div>
                <p className={styles['footer-text']}>
                  {percentage(data.pending / data.total)} Pending
                </p>
              </div>
            </div>
            <div className={styles['footer-item']}>
              <div>
                <div className={styles['footer-value']}>
                  <span>{separateThousands(data.expired)}</span>
                  <strong>&nbsp;/&nbsp;{separateThousands(data.total)}</strong>
                </div>
                <p className={styles['footer-text']}>
                  {percentage(data.expired / data.total)} Expired
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}
