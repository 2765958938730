import { Outlet } from 'react-router-dom'

import styles from './BasicLayout.module.css'
import Navbar from '../components/Menu/navbar'

export default function BasicLayout(): JSX.Element {
  return (
    <div>
      <Navbar />
      <div className={styles.main}>
        {/* <SideMenu /> */}
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
