import { useState, useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { ExpenseAndIncomeComparisonItem } from '../../../api/lending'

import styles from './Chart.module.css'

interface Props {
  data?: ExpenseAndIncomeComparisonItem[]
}

export default function RunningBalance({ data }: Props): JSX.Element {
  const [chart, setChart] = useState<echarts.ECharts | null>(null)
  const chartDom = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!chartDom.current) {
      return
    }

    const c = echarts.init(chartDom.current)

    c.setOption({
      legend: {
        right: 100,
      },
      tooltip: {},
      xAxis: {
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 45,
        },
      },
      yAxis: {
        name: 'Running Balance £',
        nameLocation: 'middle',
        nameGap: 50,
      },
      grid: {},
      series: [
        {
          name: 'Balance',
          type: 'line',
          lineStyle: {
            color: '#59cc9b',
          },
          itemStyle: {
            color: '#59cc9b',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              global: false,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(89, 204, 155, 0.5)',
                },
                {
                  offset: 1,
                  color: 'rgba(89, 204, 155, 0)',
                },
              ],
            },
          },
          data: [],
        },
        {
          name: 'Zero Balance',
          type: 'line',
          lineStyle: {
            color: '#59cc9b',
            width: 2,
            type: 'dashed',
          },
          itemStyle: {
            color: '#59cc9b',
          },
          data: [],
        },
      ],
    })

    setChart(c)

    return () => {
      c.dispose()
    }
  }, [])

  useEffect(() => {
    if (!chart) {
      return
    }

    if (!data) {
      chart.showLoading()
      return
    }

    chart.hideLoading()

    const months = data.map((item) => item.date)
    const balances = data.map((item) => item.runningBalance)
    const zeros = data.map(() => 0)

    chart.setOption({
      xAxis: {
        data: months,
      },
      series: [
        {
          name: 'Balance',
          data: balances,
        },
        {
          name: 'Zero Balance',
          data: zeros,
        },
      ],
    })
  }, [chart, data])

  return (
    <div className={styles.container}>
      <div className={styles.title}>Running Balance Monthly</div>
      <div className={styles.chart} ref={chartDom}></div>
    </div>
  )
}
