import { useContext } from 'react'//, useState
import { useNavigate } from 'react-router-dom'

import { useUserInfo } from '../../hooks/user-info'
import { UserInfoContext } from '../../context/user-info'
import { removeToken, removeUserInfo } from '../../utilities/storage'
import { useProSidebar } from 'react-pro-sidebar';
import { MdOutlineExitToApp } from "react-icons/md";

import styles from './index.module.css'
import { routes } from '../../constants/routes'
import { Divide as Hamburger } from 'hamburger-react'

export default function PageHeader(): JSX.Element {
  const { collapseSidebar } = useProSidebar();
  const navigate = useNavigate()
  // const [isOpen, setOpen] = useState(false)
  const { setUserInfo } = useContext(UserInfoContext)
  const userInfo = useUserInfo()
  const username = userInfo?.username

  const handleLogout = (): void => {
    setUserInfo(null)
    removeToken()
    removeUserInfo()

    navigate(routes.login)
  }

  return (
    <header className={styles.header}>
  <div className={styles.leftContent}>
    
    {username && 
      <Hamburger onToggle={toggled => { collapseSidebar(!toggled); }} />
    }
  </div>
  <div className={styles.logo_container}>
    <div className={styles.logo} />
  </div>
  <div className={styles.rightContent}>
 
    {username && (
      <div className={styles.info}>
            <img src="/Assets/images/account.svg" width={30} height={30} alt="User Icon" className={styles.icon} />
        <span>{username}</span>
        <span className={styles.logout} onClick={handleLogout}>
          <MdOutlineExitToApp className={styles.logoutIcon}/>
          <span className={styles['logout-text']}>Log Out</span>
        </span>
      </div>
    )}
  </div>
</header>

  )
}
