import { useState, useEffect, useRef } from 'react'
import * as echarts from 'echarts'
import { ExpenseAndIncomeComparisonItem } from '../../../api/lending'

import styles from './Chart.module.css'

interface Props {
  data?: ExpenseAndIncomeComparisonItem[]
}

export default function ComparisonOfExpensesToSavings({
  data,
}: Props): JSX.Element {
  const [chart, setChart] = useState<echarts.ECharts | null>(null)
  const chartDom = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!chartDom.current) {
      return
    }

    const c = echarts.init(chartDom.current)

    c.setOption({
      legend: {
        right: 100,
      },
      tooltip: {},
      xAxis: {
        data: [],
        axisLabel: {
          interval: 0,
          rotate: 45,
        },
      },
      yAxis: {
        name: 'Amount £',
        nameLocation: 'middle',
        nameGap: 50,
      },
      grid: {},
      series: [
        {
          name: 'Expenses',
          type: 'bar',
          itemStyle: {
            color: 'rgba(255, 178, 121, 0.8)',
          },
          data: [],
        },
        {
          name: 'Savings',
          type: 'bar',
          itemStyle: {
            color: 'rgba(57, 163, 166, 0.8)',
          },
          data: [],
        },
        {
          name: 'Income',
          type: 'line',
          lineStyle: {
            color: '#22577b',
            width: 2,
            type: 'dashed',
          },
          itemStyle: {
            color: '#22577b',
          },
          data: [],
        },
        {
          name: 'OVD Bound',
          type: 'line',
          lineStyle: {
            color: '#3c39a3',
            width: 2,
            type: 'dashed',
          },
          itemStyle: {
            color: '#3c39a3',
          },
          data: [],
        },
      ],
    })

    setChart(c)

    return () => {
      c.dispose()
    }
  }, [])

  useEffect(() => {
    if (!chart) {
      return
    }

    if (!data) {
      chart.showLoading()
      return
    }

    chart.hideLoading()

    const date = data.map((item) => item.date)
    const expenses = data.map((item) => item.expense)
    const savings = data.map((item) => item.savings)
    const income = data.map((item) => item.income)
    const ovdBound = data.map((item) => item.overdraft)

    chart.setOption({
      xAxis: {
        data: date,
      },
      series: [
        {
          name: 'Expenses',
          data: expenses,
        },
        {
          name: 'Savings',
          data: savings,
        },
        {
          name: 'Income',
          data: income,
        },
        {
          name: 'OVD Bound',
          data: ovdBound,
        },
      ],
    })
  }, [chart, data])

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Comparison of Expenses to Savings and Overdraft Use
      </div>
      <div className={styles.chart} ref={chartDom}></div>
    </div>
  )
}
