import { ReactNode } from 'react'

import styles from './index.module.css'

interface Props {
  spinning?: boolean
  children?: ReactNode
}

export default function Spin({ spinning, children }: Props): JSX.Element {
  const nested = !children
  const showSpin = children ? spinning : true

  return (
    <div
      className={`${styles.container} ${
        nested ? styles.nested : styles.wrapped
      }`}
    >
      {children ? <div>{children}</div> : null}
      {showSpin ? (
        <div className={styles.spin}>
          <svg viewBox="25 25 50 50">
            <circle cx="50" cy="50" r="20"></circle>
          </svg>
        </div>
      ) : null}
    </div>
  )
}
