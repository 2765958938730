import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserInfoContext } from '../../context/user-info';
import { useUserInfo } from '../../hooks/user-info';
import { removeToken, removeUserInfo } from '../../utilities/storage';
import { routes } from '../../constants/routes';
import { MdOutlineExitToApp } from "react-icons/md";

import styles from './Navbar.module.css'; // Update this path if necessary

const Navbar = () => {
  const navigate = useNavigate();
  const { setUserInfo } = useContext(UserInfoContext);
  const userInfo = useUserInfo();
  const username = userInfo?.username;

  const handleLogout = () => {
    setUserInfo(null);
    removeToken();
    removeUserInfo();
    navigate(routes.login);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logoContainer}>
        <NavLink to={routes.home} className={styles.logoLink}>
          <img src="/Assets/images/Finexos_Logo.png" alt="Company Logo" className={styles.logo} />
        </NavLink>
      </div>
      <ul className={styles.navList}>
        <li>
        <NavLink to="/resources" className={styles.navItem}>Resources</NavLink>
          
        </li>
        <li>
        <NavLink to="/dashboards" className={styles.navItem}>Dashboards</NavLink>
        </li>
        <li>
          <NavLink to="/executive-summary" className={styles.navItem}>Executive Summary</NavLink>
        </li>
        <li>
          <NavLink to="/lending-decision" className={styles.navItem}>Lending Decision Assistant</NavLink>
        </li>
        <li>
          <NavLink to="/decisions-made" className={styles.navItem}>Decisions Made</NavLink>
        </li>
        <li>
          <NavLink to="/configuration" className={styles.navItem}>Configuration</NavLink>
        </li>
      </ul>
      <div className={styles.userControls}>
        {username && (
          <div className={styles.userInfo}>
            <img src="/Assets/images/account.svg" width={30} height={30} alt="User Icon"  className={styles.userIcon} />
            <span className={styles.username}>{username}</span>
            <button onClick={handleLogout} className={styles.logoutButton}>
              <MdOutlineExitToApp size={24} />
              Log Out
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
