import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

import Button from '../../components/Button'
import Tabs from '../../components/Tabs'
import Pagination from '../../components/Pagination'
import Sort, { Order } from '../../components/Sort'
import Spin from '../../components/Spin'
import IconTULogo from '../../components/Icon/IconTULogo'
import {
  getDecisionList,
  DecisionItem,
  exportDecisionList,
} from '../../api/decision'
import { routes } from '../../constants/routes'
import { percentage, separateThousands } from '../../utilities/num'
import Modal from '../../components/Modal'

import styles from './index.module.css'

const { TabPane } = Tabs

export default function DecisionsMade(): JSX.Element {
  const [showType, setShowType] = useState<string | number>('selected')

  const [sortBy, setSortBy] = useState<string>('approveTime')
  const [order, setOrder] = useState<Order>('desc')

  const [loading, setLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [decisionList, setDecisionList] = useState<DecisionItem[]>([])

  const fetchDecisionList = async (
    showType: string | number = 'selected',
    sort: string = 'approveTime',
    sortOrder: Order = 'desc',
    page = 1
  ): Promise<void> => {
    let sortInQuery: string | undefined = undefined

    if (sort && sortOrder) {
      sortInQuery = `${sort},${sortOrder}`
    }

    setLoading(true)

    try {
      const response = await getDecisionList({
        showType: showType === 'selected' ? '1' : '2',
        page: page - 1,
        size: 10,
        sort: sortInQuery,
      })

      setTotal(response.totalElements)
      setPage(response.pageable.pageNumber + 1)
      setDecisionList(response.content)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleSort = (name: string, value: Order): void => {
    setSortBy(name)
    setOrder(value)

    fetchDecisionList(showType, name, value, 1)
  }

  const handlePageChange = (value: number): void => {
    fetchDecisionList(showType, sortBy, order, value)
  }

  useEffect(() => {
    fetchDecisionList()
  }, [])

  const handleTabChange = (value: string | number): void => {
    setShowType(value)
    setSortBy('approveTime')
    setOrder('desc')

    setTotal(0)
    setPage(1)
    setDecisionList([])

    fetchDecisionList(value, 'approveTime', 'desc', 1)
  }

  const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false)
  const [exporting, setExporting] = useState<boolean>(false)

  const handleExport = (): void => {
    setConfirmationVisible(true)
  }

  const handleModalClose = (): void => {
    setConfirmationVisible(false)
  }

  const handleSubmitExport = async (): Promise<void> => {
    setExporting(true)

    try {
      const response = await exportDecisionList()

      const downloadUrl = window.URL.createObjectURL(new Blob([response]))

      const link = document.createElement('a')

      link.href = downloadUrl

      const date = format(new Date(), 'ddMMyyyy')

      link.setAttribute('download', `Selected-Applicant-${date}.xlsx`) //any other extension
      document.body.appendChild(link)

      link.click()
      link.remove()

      setConfirmationVisible(false)
    } catch (err) {
      console.error(err)
    } finally {
      setExporting(false)
    }
  }

  return (
    <div>
      <div className={styles.tabs}>
        <div className="content-container">
          <Tabs defaultActive="selected" onChange={handleTabChange}>
            <TabPane tab="Selected" key="selected" />
            <TabPane tab="Expired" key="expired" />
          </Tabs>
        </div>
      </div>
      <div className="content-container">
        <div className={styles['page-title']}>
          <span>
            {showType === 'selected' ? 'Selected' : 'Expired'} Borrower List
          </span>
          {showType === 'selected' ? (
            <Button size="sm" onClick={handleExport}>
              Export
            </Button>
          ) : null}
        </div>
        <Spin spinning={loading}>
          <div className={styles.main}>
            <div className={styles['table-box']}>
              <table>
                <thead>
                  <tr>
                    <th>
                      <div className={styles.title}>
                        <span>UIC</span>
                        <Sort
                          name="uic"
                          sortBy={sortBy}
                          order={order}
                          onChange={handleSort}
                        />
                      </div>
                    </th>
                    {showType === 'selected' ? (
                      <th>
                        <div className={styles.title}>
                          <span>Selected on</span>
                          <Sort
                            name="approveTime"
                            sortBy={sortBy}
                            order={order}
                            onChange={handleSort}
                          />
                        </div>
                      </th>
                    ) : (
                      <th>
                        <div className={styles.title}>
                          <span>Applied on</span>
                          <Sort
                            name="createTime"
                            sortBy={sortBy}
                            order={order}
                            onChange={handleSort}
                          />
                        </div>
                      </th>
                    )}
                    <th>
                      <div className={styles.title}>
                        <span>Loan purpose</span>
                        <Sort
                          name="purpose"
                          sortBy={sortBy}
                          order={order}
                          onChange={handleSort}
                        />
                      </div>
                    </th>
                    <th>
                      <div className={styles.title}>
                        <span>Loan amount requested</span>
                        <Sort
                          name="amount"
                          sortBy={sortBy}
                          order={order}
                          onChange={handleSort}
                        />
                      </div>
                    </th>
                    <th>
                      <div className={styles.title}>
                        <span>Terms (months)</span>
                        <Sort
                          name="terms"
                          sortBy={sortBy}
                          order={order}
                          onChange={handleSort}
                        />
                      </div>
                    </th>
                    <th>
                      <div className={styles.title}>
                        <span>APR%</span>
                        <Sort
                          name="apr"
                          sortBy={sortBy}
                          order={order}
                          onChange={handleSort}
                        />
                      </div>
                    </th>
                    <th>
                      <div className={styles.title}>
                        <span>Estimated Monthly Payment</span>
                        <Sort
                          name="estimateMonthlyRepayment"
                          sortBy={sortBy}
                          order={order}
                          onChange={handleSort}
                        />
                      </div>
                    </th>
                    <th>
                      <div className={styles.title}>
                        <span>FCS</span>
                        <Sort
                          name="fcs"
                          sortBy={sortBy}
                          order={order}
                          onChange={handleSort}
                        />
                      </div>
                    </th>
                    <th>
                      <div className={styles.title}>
                        <span>PD</span>
                        <Sort
                          name="probabilityDefault"
                          sortBy={sortBy}
                          order={order}
                          onChange={handleSort}
                        />
                      </div>
                    </th>
                    <th>
                      <div className={styles.title}>
                        <span>Details</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {decisionList.map((item) => (
                    <tr key={item.id}>
                      <td className={styles.uic}>{item.uic}</td>
                      {showType === 'selected' ? (
                        <td>
                          {item.approveTime
                            ? format(new Date(item.approveTime), 'yyyy-MM-dd')
                            : ''}
                        </td>
                      ) : (
                        <td>
                          {item.createTime
                            ? format(new Date(item.createTime), 'yyyy-MM-dd')
                            : ''}
                        </td>
                      )}
                      <td>{item.purpose}</td>
                      <td>£{separateThousands(item.amount)}</td>
                      <td>{item.terms}</td>
                      <td>{percentage(item.apr || 0)}</td>
                      <td>£{item.estimateMonthlyRepayment}</td>
                      <td>{item.fcs || 0}</td>
                      <td>{percentage(item.probabilityDefault || 0)}</td>
                      <td>
                        <div className={styles['detail-cell']}>
                          <span className={styles['detail-icon-disabled']}>
                            <IconTULogo />
                          </span>
                          <span>
                            <Link
                              to={routes.lendingDecisionDetails}
                              state={{
                                id: item.id,
                                backTo: 'decisions-made',
                                activeMenu: routes.decisionsMade,
                              }}
                            >
                              <img src="/Assets/images/search.svg" width={40} height={40} alt="settings"/>
                            </Link>
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.footer}>
              <div className={styles.metadata}>
                <div className={styles['metadata-item']}>
                  <span>Total</span>
                  <strong>{total}</strong>
                </div>
              </div>
              <div className={styles['footer-control']}>
                <Pagination
                  total={total}
                  current={page}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
      <Modal visible={confirmationVisible} onClose={handleModalClose}>
        <div className={styles['modal-box']}>
          <div className={styles['modal-heading']}>
            <strong>Double Confirm</strong>
          </div>
          <div className={styles['modal-content']}>
            <p>Would you like to export all selected applicants?</p>
          </div>
          <div className={styles['modal-footer']}>
            <div className={styles['modal-btn']}>
              <Button type="secondary" size="sm" onClick={handleModalClose}>
                Cancel
              </Button>
              <Button
                size="sm"
                loading={exporting}
                onClick={handleSubmitExport}
              >
                Export
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
