import { useState, useEffect } from 'react'

import styles from './index.module.css'

interface Props {
  total: number
  current?: number
  defaultCurrent?: number
  defaultPageSize?: number
  onChange?: (page: number, pageSize: number) => void
}

export default function Pagination({
  total,
  current,
  defaultCurrent = 1,
  defaultPageSize = 10,
  onChange,
}: Props): JSX.Element | null {
  const [page, setPage] = useState(defaultCurrent)
  const pageSize = defaultPageSize
  const totalPages = Math.ceil(total / pageSize)
  const previousAvailable = page > 1
  const nextAvailable = page < totalPages

  const handlePrevious = (): void => {
    if (!previousAvailable) {
      return
    }

    const value = Math.max(1, page - 1)

    setPage(value)

    onChange && onChange(value, pageSize)
  }

  const handleClick = (page: number): void => {
    setPage(page)

    onChange && onChange(page, pageSize)
  }

  const handleNext = (): void => {
    if (!nextAvailable) {
      return
    }

    const value = Math.min(totalPages, page + 1)

    setPage(value)

    onChange && onChange(value, pageSize)
  }

  useEffect(() => {
    if (!current) {
      return
    }

    setPage(current)
  }, [current])

  if (totalPages <= 0) {
    return null
  }

  const displayPages = 10
  const pageArray = []
  let startPage = Math.max(1, page - Math.floor(displayPages / 2))
  let endPage = Math.min(totalPages, startPage + displayPages - 1)

  if (endPage - startPage + 1 < displayPages) {
    startPage = Math.max(1, endPage - displayPages + 1)
  }

  for (let i = startPage; i <= endPage; i++) {
    pageArray.push(
      <span
        key={i}
        className={`${styles.btn} ${page === i ? styles.active : ''}`}
        onClick={() => handleClick(i)}
      >
        {i}
      </span>
    )
  }

  return (
    <div>
      <span
        className={`${styles.btn} ${styles.toggle} ${
          previousAvailable ? '' : styles.disabled
        }`}
        onClick={handlePrevious}
      >
        Previous
      </span>
      {pageArray}
      <span
        className={`${styles.btn} ${styles.toggle}  ${
          nextAvailable ? '' : styles.disabled
        }`}
        onClick={handleNext}
      >
        Next
      </span>
    </div>
  )
}
