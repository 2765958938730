import { UserInfo } from '../context/user-info'

const tokenKey = 'authorization_token'

export function setToken(value: string): void {
  window.sessionStorage.setItem(tokenKey, value)
}

export function getToken(): string {
  return window.sessionStorage.getItem(tokenKey) || ''
}

export function hasToken(): boolean {
  const token = getToken()

  return !!token
}

export function removeToken(): void {
  window.sessionStorage.removeItem(tokenKey)
}

const userInfoKey = 'user_info'

export function setUserInfo(value: UserInfo | null): void {
  if (value) {
    window.sessionStorage.setItem(userInfoKey, JSON.stringify(value))
  } else {
    window.sessionStorage.removeItem(userInfoKey)
  }
}

export function getUserInfo(): UserInfo | null {
  let userInfo: UserInfo | null = null

  try {
    const data = window.sessionStorage.getItem(userInfoKey)

    if (data) {
      userInfo = JSON.parse(data)
    }
  } catch (err) {
    console.error(err)
  }

  return userInfo
}

export function hasUserInfo(): boolean {
  const userInfo = getUserInfo()

  return !!userInfo
}

export function removeUserInfo(): void {
  window.sessionStorage.removeItem(userInfoKey)
}
