import {
  PropsWithChildren,
  Children,
  Key,
  isValidElement,
  useState,
} from 'react'

import styles from './index.module.css'

interface Props extends PropsWithChildren {
  defaultActive?: Key
  onChange?: (key: Key) => void
}

export default function Tab({
  defaultActive,
  children,
  onChange,
}: Props): JSX.Element {
  const [active, setActive] = useState<Key | undefined>(defaultActive)

  const handleToggle = (key: Key): void => {
    setActive(key)

    onChange && onChange(key)
  }

  return (
    <div>
      <div className={styles['tab-box']}>
        {Children.map(children, (item, index) => {
          let key: Key = ''
          let content: JSX.Element | null = null

          if (isValidElement(item)) {
            key = item.key || `tab-${index}`
            content = item.props.tab
          } else {
            key = `tab-${index}`
          }

          return (
            <div
              key={key}
              className={`${styles['tab-item']} ${
                active === key ? styles['tab-item-active'] : ''
              }`}
              onClick={(): void => handleToggle(key)}
            >
              <span>{content}</span>
            </div>
          )
        })}
      </div>
      <div>{children}</div>
    </div>
  )
}
