import request from './request'

interface GetDecisionListRequest {
  showType: string
  page?: number
  size?: number
  sort?: string
}

export interface DecisionItem {
  id: string
  uic: string
  amount: number
  terms: number
  purpose: string
  estimateMonthlyRepayment: number
  fcs: number
  probabilityDefault: number
  maxAffordability: number
  createTime: string
  leftDays: number
  apr: number
  approveTime: string
}

interface GetDecisionListResponse {
  content: DecisionItem[]
  totalElements: number
  totalPages: number
  pageable: {
    pageNumber: number
    pageSize: number
  }
}

export function getDecisionList(
  payload: GetDecisionListRequest
): Promise<GetDecisionListResponse> {
  return request.get('/admin/loans/decisions', {
    params: payload,
  })
}

export function exportDecisionList(): any {
  return request.get('/admin/loans/decisions/excel', {
    responseType: 'blob',
  })
}
