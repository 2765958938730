import { useState, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AuthLayout from './layouts/AuthLayout'
import BasicLayout from './layouts/BasicLayout'
import Login from './pages/Auth/Login'
import ExecutiveSummary from './pages/ExecutiveSummary'
import Resources from './pages/Resources'
import LendingDecision from './pages/LendingDecision'
import LendingDecisionDetail from './pages/LendingDecision/Detail'
import DecisionsMade from './pages/DecisionsMade'
import Configuration from './pages/Configuration'
import Dashboards from './pages/Dashboards'
import Container from 'react-bootstrap/Container';
import { routes } from './constants/routes'
import { UserInfoContext, UserInfo } from './context/user-info'
import {
  hasToken,
  getUserInfo,
  setUserInfo as saveUserInfo,
} from './utilities/storage'

import './App.css'

const RequireAuth = ({ children }: { children: JSX.Element }): JSX.Element => {
  const auth = hasToken()

  if (!auth) {
    return <Navigate to={routes.login} replace />
  }

  return children
}

const initialUserInfo = getUserInfo()

export default function App(): JSX.Element {
  const [userInfo, setUserInfo] = useState<UserInfo | null>(initialUserInfo)

  useEffect(() => {
    saveUserInfo(userInfo)
  }, [userInfo])

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      <Container fluid>
      <Routes>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path={routes.login} element={<Login />} />
        </Route>
          <Route
            path="/"
            element={
              <RequireAuth>
                <BasicLayout />
              </RequireAuth>
            }
          >
            <Route path={routes.home} element={<Resources />} />
            <Route
              path={routes.dashboards}
              element={<Dashboards />}
            />
            <Route
              path={routes.executiveSummary}
              element={<ExecutiveSummary />}
            />
            <Route path={routes.lendingDecision} element={<LendingDecision />} />
            <Route
              path={routes.lendingDecisionDetails}
              element={<LendingDecisionDetail />}
            />
            <Route path={routes.decisionsMade} element={<DecisionsMade />} />
            <Route path={routes.configuration} element={<Configuration />} />
            <Route path={routes.resources} element={<Resources />} />
            <Route
              path={routes.resources}
              element={<Resources />}
            />
          </Route>
        </Routes>
      </Container>
    </UserInfoContext.Provider>
  )
}
