import { PropsWithChildren } from 'react'
import classnames from 'classnames'

import styles from './index.module.css'

interface Props extends PropsWithChildren {
  type?: 'success'
  onClose?: () => void
}

export default function Alert({
  type = 'success',
  onClose,
  children,
}: Props): JSX.Element {
  const classes = classnames(styles.alert, styles[type])

  const handleClose = (): void => {
    onClose && onClose()
  }

  return (
    <div className={classes}>
      <span className={styles.icon}></span>
      <div className={styles.content}>{children}</div>
      {onClose && (
        <span className={styles.close} onClick={handleClose}>
          &times;
        </span>
      )}
    </div>
  )
}
