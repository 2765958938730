export function isNotNumber(data: any): boolean {
  return isNaN(data) || data === null || data === '';
}

export function separateThousands(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function percentage(num: number): string {
  return (num * 100).toFixed(2) + '%'
}
