import request from './request'

interface ExecutiveSummaryRequest {
  from?: string
  to?: string
}

interface ExecutiveSummaryDistribution {
  group: string
  num: number
  rate: number
}

export interface ExecutiveSummaryResponse {
  approved: number
  expired: number
  pending: number
  total: number
  distribution: ExecutiveSummaryDistribution[]
}

export function getExecutiveSummary(
  payload?: ExecutiveSummaryRequest
): Promise<ExecutiveSummaryResponse> {
  return request.get('/admin/loans/summary', {
    params: payload,
  })
}

interface GetLendingListRequest {
  page?: number
  size?: number
  sort?: string
}

export interface LendingItem {
  id: string
  uic: string
  amount: number
  terms: number
  purpose: string
  estimateMonthlyRepayment: number
  fcs: number
  probabilityDefault: number
  maxAffordability: number
  createTime: string
  leftDays: number
  apr: number
}

interface GetLendingListResponse {
  content: LendingItem[]
  totalElements: number
  totalPages: number
  pageable: {
    pageNumber: number
    pageSize: number
  }
}

export function getLendingList(
  payload: GetLendingListRequest
): Promise<GetLendingListResponse> {
  return request.get('/admin/loans/assistant', {
    params: payload,
  })
}

export interface ExpenseAndIncomeItem {
  month: string
  expense: number
  income: number
}

export interface ExpenseAndIncomeComparisonItem {
  date: string
  expense: number
  income: number
  overdraft: number
  savings: number
  runningBalance: number
}

export interface AnnualExpensesItem {
  category: string
  year: string
  value: number
}

export interface ExpenseDistributionItem {
  value: number
  name: string
}

export interface ExpenseComparisonItem {
  value: number
  name: string
}

export interface LendingDetail {
  amount?: number
  apr?: number
  createTime?: string
  averageMonthlyIncome?: number
  estimateMonthlyRepayment?: number
  estimatedLoanAmount?: number
  fcs?: number
  id?: string
  leftDays?: number
  maxAffordability?: number
  probabilityDefault?: number
  purpose?: string
  terms?: number
  uic?: string
  expenseAndIncomes?: ExpenseAndIncomeItem[]
  expenseAndIncomeComparison?: ExpenseAndIncomeComparisonItem[]
  annualExpenses?: AnnualExpensesItem[]
  expenseDistribution?: ExpenseDistributionItem[]
  expenseComparison?: ExpenseComparisonItem[]
}

export function getLendingDetail(id: string): Promise<LendingDetail> {
  return request.get(`/admin/loans/assistant/${id}`)
}

export function approveLoans(idList: string[]): Promise<void> {
  return request.put('/admin/loans/approve', {
    ids: idList,
  })
}

export type CreditScoreType = 'EXPERIAN' | 'TU' | 'EQUIFAX'

export interface GetPendingDecisionRequest {
  scoreType: CreditScoreType
  grades: string
  scores: string
}

export interface GetPendingDecisionResponseItem {
  fcs: number
  dti: number
  tuScore: number
  equifaxScore: number
  experianScore: number
}

export type GetPendingDecisionResponse = GetPendingDecisionResponseItem[]

export function getPendingApplicants(
  payload: GetPendingDecisionRequest
): Promise<GetPendingDecisionResponse> {
  return request.get('/admin/loans/pending', {
    params: payload,
  })
}
