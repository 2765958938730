import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import IconBack from '../../../components/Icon/IconBack'
import RunningBalance from '../components/RunningBalance'
import ExpensesIncome from '../components/ExpensesIncome'
import ComparisonOfExpensesToSavings from '../components/ComparisonOfExpensesToSavings'
import AnnualExpenses from '../components/AnnualExpenses'
import { getLendingDetail, LendingDetail } from '../../../api/lending'

import styles from './index.module.css'
import ExpenseComparison from '../components/ExpenseComparison'
import ExpenseDistribution from '../components/ExpenseDistribution'
import { percentage } from '../../../utilities/num'

type BackTo = 'lending-decision' | 'decisions-made'

interface LocationState {
  id: string
  backTo?: BackTo
}

const backToTitle: Record<BackTo, string> = {
  'lending-decision': 'Lending Decision Assistant',
  'decisions-made': 'Decisions Made',
}

export default function Detail(): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as LocationState | undefined
  const backTo = state?.backTo || 'lending-decision'
  const id = state?.id

  const handleBack = () => {
    navigate(-1)
  }

  const [applicantDetail, setApplicantDetail] = useState<LendingDetail>({})

  useEffect(() => {
    if (!id) {
      return
    }

    getLendingDetail(id).then((data) => {
      setApplicantDetail(data)
    })
  }, [id])

  return (
    <div className={`content-container ${styles.container}`}>
      <div className={styles.back} onClick={handleBack}>
        <span className={styles.icon}>
          <IconBack />
        </span>
        <span>{backToTitle[backTo]}</span>
      </div>
      <div className={styles.title}>Applicant Report</div>
      <div className={styles.charts}>
        <div className={styles.applicant}>
          <div className={styles.uic}>
            <label>Applicant:</label>
            <span>{applicantDetail?.uic}</span>
          </div>
          <div>
            <label>APR:</label>
            <span>{percentage(applicantDetail?.apr || 0)}</span>
            <label>Financial capability score:</label>
            <span>{applicantDetail?.fcs}</span>
          </div>
          <div>
            <label>Average monthly income:</label>
            <span>{applicantDetail?.averageMonthlyIncome}</span>
            <label>Estimated loan amount:</label>
            <span>{applicantDetail?.estimatedLoanAmount}</span>
            <label>Estimated monthly repayment:</label>
            <span>{applicantDetail?.estimateMonthlyRepayment}</span>
          </div>
        </div>
        <div className={styles.balance}>
          <RunningBalance data={applicantDetail.expenseAndIncomeComparison} />
        </div>
        <div className={styles.income}>
          <ExpensesIncome data={applicantDetail.expenseAndIncomes} />
        </div>
        <div className={styles.income}>
          <AnnualExpenses data={applicantDetail.annualExpenses} />
        </div>
        <div className={styles.expense}>
          <ExpenseDistribution data={applicantDetail.expenseDistribution} />
          <ExpenseComparison data={applicantDetail.expenseComparison} />
        </div>
        <div className={styles.income}>
          <ComparisonOfExpensesToSavings
            data={applicantDetail.expenseAndIncomeComparison}
          />
        </div>
      </div>
    </div>
  )
}
