import React from 'react';
import styles from './index.module.css';

export default function Resources(): JSX.Element {
  return (
    <div className={styles.resourcesContainer}>
      <h1 className={styles.resourcesHeader}>Resources</h1>

      <div className={styles.resourceRow}>
        <a
          href="https://demo.exzt.finexos.io/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.resourceBox}
        >
          <video className={styles.video} autoPlay loop muted>
            <source src="https://www.finexos.io/wp-content/uploads/2023/12/Video-1.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className={styles.resourceText}>
            <i className="fa fa-link"></i>Onboarding Application
          </div>
        </a>

        <a
          href="https://developers.finexos.io/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.resourceBox}
        >
          <video className={styles.video} autoPlay loop muted>
            <source src="https://www.finexos.io/wp-content/uploads/2023/12/Video-2.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className={styles.resourceText}>
            <i className="fa fa-link"></i>Developers Portal
          </div>
        </a>

        <a
          href="https://vault.finexos.io/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.resourceBox}
        >
          <video className={styles.video} autoPlay loop muted>
            <source src="https://www.finexos.io/wp-content/uploads/2023/12/Video-3.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className={styles.resourceText}>
            <i className="fa fa-link"></i>Fiola Vault
          </div>
        </a>
      </div>
    </div>
  );
}
