import styles from './index.module.css'

export type Order = 'asc' | 'desc'

interface Props {
  name: string
  sortBy?: string
  order?: Order
  onChange?: (name: string, value: Order) => void
}

export default function Sort({
  name,
  sortBy,
  order,
  onChange,
}: Props): JSX.Element {
  const handleChange = (value: Order) => {
    onChange && onChange(name, value)
  }
  const active = sortBy === name

  return (
    <span className={styles.container}>
      <span
        className={`${styles.triangle} ${styles['triangle-up']} ${
          active && order === 'asc' ? styles.active : ''
        }`}
        onClick={() => handleChange('asc')}
      ></span>
      <span
        className={`${styles.triangle} ${styles['triangle-down']}  ${
          active && order === 'desc' ? styles.active : ''
        }`}
        onClick={() => handleChange('desc')}
      ></span>
    </span>
  )
}
