import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import {
  zipObject,
  assign,
  map,
  union,
  without,
  every,
  some,
  includes,
} from 'lodash-es'
import { format } from 'date-fns'

import Checkbox from '../../components/Checkbox'
import Button from '../../components/Button'
import Pagination from '../../components/Pagination'
import Modal from '../../components/Modal'
import Alert from '../../components/Alert'
import Sort, { Order } from '../../components/Sort'
import Spin from '../../components/Spin'
import IconTULogo from '../../components/Icon/IconTULogo'
import PendingApplicant from './components/PendingApplicant'
import { getLendingList, LendingItem, approveLoans } from '../../api/lending'
import { routes } from '../../constants/routes'
import { percentage, separateThousands, isNotNumber } from '../../utilities/num'

import styles from './index.module.css'

export default function LendingDecision(): JSX.Element {
  const lendingDataSource = useRef<Record<string, LendingItem>>({})

  const [sortBy, setSortBy] = useState<string>('createTime')
  const [order, setOrder] = useState<Order>('desc')

  const [loading, setLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState<number>(0)
  const [lendingList, setLendingList] = useState<LendingItem[]>([])

  const fetchLendingList = async (
    sort: string = 'createTime',
    sortOrder: Order = 'desc',
    page = 1
  ): Promise<void> => {
    let sortInQuery: string | undefined = undefined

    if (sort && sortOrder) {
      sortInQuery = `${sort === 'leftDays' ? 'createTime' : sort},${sortOrder}`
    }

    setLoading(true)

    try {
      const response = await getLendingList({
        page: page - 1,
        size: 18,
        sort: sortInQuery,
      })

      setTotal(response.totalElements)
      setPage(response.pageable.pageNumber + 1)
      setLendingList(response.content)

      lendingDataSource.current = assign(
        {},
        lendingDataSource.current,
        zipObject(map(response.content, 'id'), response.content)
      )
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleSort = (name: string, value: Order): void => {
    setSortBy(name)
    setOrder(value)

    fetchLendingList(name, value, 1)
  }

  const handlePageChange = (value: number): void => {
    fetchLendingList(sortBy, order, value)
  }

  useEffect(() => {
    fetchLendingList()
  }, [])

  const [selectedIDList, setSelectedIDList] = useState<string[]>([])
  const selectedInfoList = map(
    selectedIDList,
    (item) => lendingDataSource.current[item] || {}
  )
  const isAll =
    lendingList.length > 0 &&
    every(lendingList, (item) => includes(selectedIDList, item.id))
  const isIndeterminate =
    lendingList.length > 0 &&
    !isAll &&
    some(lendingList, (item) => includes(selectedIDList, item.id))

  const handleSelectAll = (value: boolean): void => {
    const all = map(lendingList, 'id')

    if (value) {
      setSelectedIDList(union(selectedIDList, all))
    } else {
      setSelectedIDList(without(selectedIDList, ...all))
    }
  }

  const handleSelectChange = (id: string, value: boolean): void => {
    if (value) {
      setSelectedIDList(union(selectedIDList, [id]))
    } else {
      setSelectedIDList(without(selectedIDList, id))
    }
  }

  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [confirming, setConfirming] = useState<boolean>(false)
  const [successTipsVisible, setSuccessTipsVisible] = useState<boolean>(false)
  const [successUserCount, setSuccessUserCount] = useState<number>(0)

  const handleSelectBtnClick = (): void => {
    setModalVisible(true)
  }

  const handleModalClose = (): void => {
    setModalVisible(false)
  }

  const handleModalConfirm = async (): Promise<void> => {
    setConfirming(true)

    try {
      await approveLoans(selectedIDList)

      setSuccessUserCount(selectedIDList.length)
      setSuccessTipsVisible(true)
      setSelectedIDList([])
      setModalVisible(false)
      fetchLendingList()
    } catch (err) {
      console.error(err)
    } finally {
      setConfirming(false)
    }
  }

  const handleSuccessTipsClose = (): void => {
    setSuccessTipsVisible(false)
    setSuccessUserCount(0)
  }

  return (
    <div className={`content-container ${styles.container}`}>
      <PendingApplicant />
      {successTipsVisible && (
        <Alert onClose={handleSuccessTipsClose}>
          You have successfully approved {successUserCount} applicants !
        </Alert>
      )}
      <Spin spinning={loading}>
        <div className={styles.main}>
          <div
            className={`${styles['table-box']} ${
              successTipsVisible ? styles['table-box-compact'] : ''
            }`}
          >
            <table>
              <thead>
                <tr>
                  <th>
                    <div className={styles.title}>
                      <span>UIC</span>
                      <Sort
                        name="uic"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>Applied on</span>
                      <Sort
                        name="createTime"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>Days Left</span>
                      <Sort
                        name="leftDays"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>Financial capability score</span>
                      <Sort
                        name="fcs"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>Maximum affordability</span>
                      <Sort
                        name="maxAffordability"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>Loan amount requested</span>
                      <Sort
                        name="amount"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>Terms (months)</span>
                      <Sort
                        name="terms"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>Estimated Monthly Payment</span>
                      <Sort
                        name="estimateMonthlyRepayment"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>APR%</span>
                      <Sort
                        name="apr"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>PD</span>
                      <Sort
                        name="probabilityDefault"
                        sortBy={sortBy}
                        order={order}
                        onChange={handleSort}
                      />
                    </div>
                  </th>
                  <th>
                    <div className={styles.title}>
                      <span>Details</span>
                    </div>
                  </th>
                  <th style={{ width: 40 }}>
                    <Checkbox
                      checked={isAll}
                      indeterminate={isIndeterminate}
                      onChange={handleSelectAll}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {lendingList.map((item) => (
                  <tr key={item.id}>
                    <td className={styles.uic}>{item.uic}</td>
                    <td>
                      {item.createTime
                        ? format(new Date(item.createTime), 'yyyy-MM-dd')
                        : ''}
                    </td>
                    <td>{item.leftDays}</td>
                    <td>{isNotNumber(item.fcs) ? '-' : item.fcs}</td>
                    <td>
                      {isNotNumber(item.maxAffordability)
                        ? '-'
                        : `£${separateThousands(item.maxAffordability)}`}
                    </td>
                    <td>£{separateThousands(item.amount)}</td>
                    <td>{item.terms}</td>
                    <td>£{item.estimateMonthlyRepayment}</td>
                    <td>{percentage(item.apr || 0)}</td>
                    <td>
                      {isNotNumber(item.probabilityDefault)
                        ? '-'
                        : percentage(item.probabilityDefault)}
                    </td>
                    <td>
                      <div className={styles['detail-cell']}>
                        <span className={styles['detail-icon-disabled']}>
                          <IconTULogo />
                        </span>
                        <span>
                          <Link
                            to={routes.lendingDecisionDetails}
                            state={{ id: item.id, backTo: 'lending-decision' }}
                          >
                            <img src="/Assets/images/search.svg" width={40} height={40} alt="settings"/>
                          </Link>
                        </span>
                      </div>
                    </td>
                    <td>
                      <Checkbox
                        checked={includes(selectedIDList, item.id)}
                        onChange={(value) => handleSelectChange(item.id, value)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.footer}>
            <div className={styles.metadata}>
              <div className={styles['metadata-item']}>
                <span>Total</span>
                <strong>{total}</strong>
              </div>
              <div className={styles['metadata-item']}>
                <span>Selected</span>
                <strong>{selectedIDList.length}</strong>
              </div>
            </div>
            <div className={styles['footer-control']}>
              <div className={styles['pagination-box']}>
                <Pagination
                  total={total}
                  current={page}
                  onChange={handlePageChange}
                />
              </div>
              <Button
                size="sm"
                disabled={!selectedIDList.length}
                onClick={handleSelectBtnClick}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      </Spin>
      <Modal visible={modalVisible} onClose={handleModalClose}>
        <div className={styles['modal-box']}>
          <div className={styles['modal-heading']}>
            <strong>User Selected</strong>
            <span>Total {selectedIDList.length}</span>
          </div>
          <div className={styles['modal-content']}>
            <table className="fixed small">
              <thead>
                <tr>
                  <th>UIC</th>
                  <th>FCS</th>
                  <th>TU Score</th>
                  <th>Loan Amount</th>
                  <th>APR%</th>
                  <th>Terms</th>
                  <th>PD</th>
                </tr>
              </thead>
              <tbody>
                {selectedInfoList.map((item) => (
                  <tr key={item.id}>
                    <td className={styles.uic}>{item.uic}</td>
                    <td>{isNotNumber(item.fcs) ? '-' : item.fcs}</td>
                    <td>-</td>
                    <td>£{separateThousands(item.amount)}</td>
                    <td>{percentage(item.apr || 0)}</td>
                    <td>{item.terms}</td>
                    <td>
                      {isNotNumber(item.probabilityDefault)
                        ? '-'
                        : percentage(item.probabilityDefault)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles['modal-footer']}>
            <p className={styles['modal-text']}>
              By confirming this selection， we are moving this applicant to the
              Decision Made Table.
            </p>
            <div className={styles['modal-btn']}>
              <Button type="secondary" size="sm" onClick={handleModalClose}>
                Cancel
              </Button>
              <Button
                size="sm"
                loading={confirming}
                onClick={handleModalConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
